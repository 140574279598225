<template>
  <div>
    <b-card title="Clients">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>System Type</th>
            <th>Enabled</th>
            <th>Last collection</th>
            <th>Last test</th>
            <th>Full Cron</th>
            <th>Partial Cron</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="client in asup.details.clients"
            :key="client.id"
          >
            <td>{{ client.id }}</td>
            <td>
              <b-link :to="{ name: 'agent-detail-v2.clients-details', params: { clientId: client.id } }">
                {{ client.displayName }}
              </b-link>
            </td>
            <td>{{ client.systemType }}</td>
            <td>{{ client.enabled ? "Enabled" : "Disabled" }}</td>
            <td>
              <b-badge :variant="client.lastCollectionStatus === 0 ? 'success' : 'danger'">
                {{ client.lastCollectionStatus === 0 ? "OK" : "Error" }}
              </b-badge>
              <br>
              <small class="text-hint">{{ client.lastCollectionOn | formatDateTime }}</small></td>
            <td>
              <b-badge :variant="client.lastTestSucceeded ? 'success' : 'danger'">
                {{ client.lastTestSucceeded ? "OK" : "Error" }}
              </b-badge>
              <br><small class="text-hint">{{ client.lastTestOn | formatDateTime }}</small></td>
            <td>{{ client.fullScheduleCron }}</td>
            <td>{{ client.partialScheduleCron }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>

import { BCard, BLink, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BLink,
    BBadge,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
